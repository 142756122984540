<template>
  <NuxtLink
    :to="`/${props.cardType}/${props.cardData.id}`"
    class="card_cate"
  >
    <div class="wrap_thumb">
      <img
        :src="
          generateWebpImage(
            cardData.thumbnail,
            props.page === 'typeDouble' && isWidthStore.resolutionList.isPc
              ? 'C544x357'
              : 'C398x398',
          )
        "
        alt=""
      />
    </div>
    <div class="wrap_text">
      <span
        v-if="cardType === 'news'"
        class="cate_card"
        >{{ newsType }}</span
      >
      <div
        v-else
        class="wrap_cate"
      >
        <span
          v-if="cardData.businessName"
          class="cate_card"
          >{{ cardData.businessName }}</span
        >
        <span
          v-if="cardData.partnerName"
          class="cate_card"
          >{{ cardData.partnerName }}</span
        >
      </div>
      <strong class="tit_card">{{ cardData.title }}</strong>
      <div
        v-if="cardData.tag.length > 0"
        class="area_tags"
      >
        <span
          v-for="(item, idx) in cardData.tag"
          :key="`tag_${idx}`"
          >{{ '#' + item.name }}</span
        >
      </div>
    </div>
  </NuxtLink>
</template>
<script setup>
import { useIsWidth } from '/stores/store';
const isWidthStore = useIsWidth();
const props = defineProps({
  cardData: {
    type: Object,
    default() {
      return {};
    },
  },
  cardType: {
    type: String,
    default: '',
  },
  page: {
    type: String,
    default: '',
  },
});

const newsType = computed(() => {
  switch (props.cardData.newsType) {
    case 'REPORT':
      return '언론보도';
      break;
    case 'INSIGHT':
      return '인사이트';
      break;
    case 'NOTICE':
      return '알립니다';
      break;
    default:
      return '';
      break;
  }
});
</script>
<style lang="scss" scoped>
.card_cate {
  display: block;
}
.wrap_thumb {
  line-height: 0;
  img {
    width: 100%;
  }
}
.wrap_text {
  margin-top: 24px;
  .cate_card {
    font-size: 15px;
    color: #666;
  }
  .wrap_cate {
    .cate_card {
      & + .cate_card {
        &:before {
          content: '∙';
          display: inline-block;
          vertical-align: top;
          margin: 0 4px;
        }
      }
    }
  }
  .tit_card {
    display: -webkit-box;
    overflow: hidden;
    max-width: 100%;
    margin-top: 8px;
    font-size: 20px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    + .area_tags {
      margin-top: 8px;
    }
  }
  .area_tags {
    overflow: hidden;
    width: 100%;
    height: 19px;
    margin-left: -8px;
    font-size: 15px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    span {
      margin-left: 8px;
      word-break: break-all;
    }
  }
}
@media screen and (max-width: 811px) {
  .wrap_text {
    .cate_card {
      font-size: 14px;
    }
    .tit_card {
      font-size: 18px;
    }
    .area_tags {
      height: 21px;
      font-size: 14px;
    }
  }
}
</style>
